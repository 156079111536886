model-viewer {
  cursor: grab;
  display: flex;
  justify-content: center;
  height: 75vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.2);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

/* ERROR && LOADING SCREEN && PROMPT */

/* This keeps child nodes hidden while the element loads */
:not(:defined) > {
  display: none;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 300px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

.fading-words {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  width: 300px;
}

.fading-words .word {
  opacity: 0;
  text-align: center;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  padding: 0 5px;
  width: 100%;
}

.fading-words .active {
  opacity: 1;
  width: 100%;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  translate: -50% 0;
  bottom: 200px;
  display: none;
}

model-viewer[ar-tracking="not-tracking"] > #ar-failure {
  display: block;
}

#progress {
  position: absolute;
  width: calc(100% / 3);
  height: 10px;
  color: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.25);
  border: 2px solid currentColor;
  border-radius: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 100% / 6);
  opacity: 0;
  transition: opacity 0.3s 0.3s;
}

#progress.show {
  transition-delay: 0s;
  opacity: 1;
}

#progress > .bar {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  transform-origin: top left;
  transform: scaleX(0);
}
