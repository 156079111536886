@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  color: #a10c1b;
  background: #fac44788;
}
